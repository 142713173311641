import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store/index.js";

function onlyAdmins () {
  // function onlyAdmins (to) {
    if (store.state.user_config?.role != "admin") {
      return { name: "NoAccess" }
    } else {
      // console.log(to)
    }
  }

function onlySystemAdmin(){
  console.log(store.state.system_access)
  if (store.state.system_access?.isSystemAdmin) {
    // console.log(to)
  } else {
    return { name: "NoAccess" }
  }
}
  
// function onlyUsers() {
//   if (store.state.user_config?.type != "Admin") {
//     return { name: "NoAccess" }
//   }
// }

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/posts",
    name: "PostList",
    component: () => import("../views/PostList.vue"),
  },
  {
    path: "/posts/:post_id",
    name: "PostEdit",
    component: () => import("../views/PostEdit.vue"),
  },
  {
    path: "/usermgmt",
    name: "UserMgmt",
    component: () => import("../views/UserMgmt.vue"),
    beforeEnter: [onlyAdmins],
  },
  {
    path: "/adminusermgmt",
    name: "AdminUserMgmt",
    component: () => import("../views/adminUserMgmt.vue"),
    beforeEnter: [onlySystemAdmin], 
  },
  {
    path: "/contactformconfig",
    name: "ContactFormConfig",
    component: () => import("../views/ContactFormConfig.vue"),
  },
  {
    path: "/contactlist",
    name: "ContactList",
    component: () => import("../views/ContactList.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/UserLogin.vue"),
  },
  // {
  //   path: "/DevConsole",
  //   name: "DevConsole",
  //   component: () => import("../views/DevConsole.vue"),
  //   beforeEnter: [onlySystemAdmin],
  // }
  // {
  //   path: "/test",
  //   name: "test",
  //   component: () => import("../views/TestView.vue"),
  // },
  // {
  //   path: "/WysiwygVisualCheck",
  //   name: "WysiwygVisualCheck",
  //   component: () => import("../views/WysiwygVisualCheck.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
