import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// --------------------------------------------------------
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { getStorage } from "firebase/storage";
// --------------------------------------------------------
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
// --------------------------------------------------------
// npm install --save @ckeditor/ckeditor5-vue @ckeditor/ckeditor5-build-classic
import CKEditor from '@ckeditor/ckeditor5-vue'; // https://ckeditor.com/docs/ckeditor5/latest/installation/integrations/vuejs-v3.html

// ------------- load firebase configuration ------------------
import {firebaseConfig} from "../config_cms"
// -------------------------------------------------------------------

export const firebase_app = initializeApp(firebaseConfig);
const fb_auth = getAuth();
const fb_funcs = getFunctions(firebase_app, "asia-southeast1");
const fb_firestoreDB = getFirestore(firebase_app);
// const fb_storage = getStorage(firebase_app);
export { fb_auth, fb_funcs, fb_firestoreDB };

loadFonts(); // for vuetify

createApp(App).use(router).use(store).use(vuetify).use(CKEditor).mount("#app");
